import baseConstants from "./baseConstants";

const requestConstants = {
  API_BASE_URL: baseConstants["BASE_URL"],
  API_ONBOARDING_URL: baseConstants["ONBOARDING_URL"],
  LANGUAGE_DATA: {
    URL: "/api/webTranslations/{language}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REMOVE_ACCOUNT_BY_FACEBOOK: {
    URL: "/api/patron/removeByFacebook",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REMOVE_ACCOUNT_BY_GOOGLE: {
    URL: "/api/patron/removeByGoogle",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REMOVE_ACCOUNT: {
    URL: "/api/patron/remove",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_USER_DATA: {
    URL: "/api/patron/get",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  UPDATE_USER_DATA: {
    URL: "/api/patron/update",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  CONFIRM_USER_PHONE: {
    URL: "/api/patron/confirmPhone",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  CHANGE_PASSWORD: {
    URL: "/api/patron/changePassword",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  LOGIN: {
    URL: "/login/login",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  LOGIN_FACEBOOK: {
    URL: "/login/facebookPluginLogin",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  APPLE_FACEBOOK: {
    URL: "/login/applePluginLogin",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  LOGIN_GOOGLE: {
    URL: "/login/googlePluginLogin",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  LOGOUT: {
    URL: "/login/logout",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REGISTRATION: {
    URL: "/api/patron/register",
    URL_MAIL: "/api/patron/byMailRegister",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REGISTRATION_FACEBOOK: {
    URL: "/api/patron/register",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REGISTRATION_GOOGLE: {
    URL: "/api/patron/register",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  REGISTRATION_ACTIVATION: {
    URL: "/api/patron/activateByPlugin",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESTORE_PASSWORD: {
    URL: "/api/patron/restore-password",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  CONFIGURATION: {
    URL: "/api/configuration",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_TEMPORARY_BOOKING: {
    URL: "/api/reservation/temporaryBooking",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_PRE_CONFIRM_BOOKING: {
    URL: "/api/reservation/preConfirmBooking",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  CALCULATE_PAYMENT: {
    URL: "/api/reservation/calculatePayment",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_CONFIRM_BOOKING: {
    URL: "/api/reservation/confirmBooking",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_HISTORY: {
    URL: "/api/reservation/history",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_BY_TOKEN: {
    URL: "/api/reservation/token",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_BY_TOKEN_CANCEL: {
    URL: "/api/reservation/cancelByToken",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_CURRENT: {
    URL: "/api/reservation/current",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_REMOVE: {
    URL: "/api/reservation/remove",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_CANCEL: {
    URL: "/api/reservation/cancel",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESTAURANT_LIST: {
    URL: "/api/restaurant/search",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESTAURANT_DETAILS: {
    URL: "/api/restaurant/get/{uuid}?preferences=true",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESTAURANT_SINGLE_RESTAURANT: {
    URL: "/api/restaurant/searchSingleRestaurant",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESTAURANT_GALLERY: {
    URL: "/api/restaurant/images/{restaurantUUID}/{from}/{to}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  PROMOTIONS_FOR_LOCATION: {
    URL: "/api/promotions/for-location",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESTAURANT_PROMOTIONS: {
    URL: "/api/promotions/for-restaurant/{restaurantName}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  RESERVATION_PAYMENT_URL: {
    URL: "/api/payment/getPaymentUrl",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_ACTIVE_EVENT: {
    URL: "/api/event/active",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_ONBOARDING_CONFIG: {
    URL: "/onboarding/configuration",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_ONBOARDING_DISCOUNT_CONFIG: {
    URL: "/onboarding/configuration?code={discountCode}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_NIP_DATA: {
    URL: "/onboarding/company/{nipNumber}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_CONTRACT_PDF: {
    URL: "/onboarding/agreement",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8",
    RESPONSE_TYPE: "blob"
  },
  POST_ONBOARDING_FORM_DATA: {
    URL: "/onboarding/register",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  POST_CHAT_ADD: {
    URL: "/api/chat/add",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  POST_CHAT_ADD_MESSAGE: {
    URL: "/api/chat/addMessage",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_CHAT_DATA: {
    URL: "/api/chat/get/{reservationId}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_RESTAURANT_CHAT_DATA: {
    URL: "/api/chat/getByToken/{token}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_COMPANY_DATA: {
    URL: "/api/company/findByNip/{nipNumber}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  GET_SURVEY_TEMPLATE: {
    URL: "/api/survey/template/{restaurantId}",
    METHOD: "get",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  POST_SURVEY_RESULTS: {
    URL: "/api/survey/add",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  CHECK_FILLED_SURVEY: {
    URL: "/api/survey/checkFilled",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  },
  POST_LOG_ERROR: {
    URL: "/api/log/error",
    METHOD: "post",
    CONTENT_TYPE: "application/json; charset=utf-8"
  }
};

export default requestConstants;
