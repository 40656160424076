import React from "react";
import slugify from "react-slugify";
import { Translation } from "react-i18next";
import { baseConstants } from "../constants";
import moment from "moment";
import { requestService } from "../services";
import { parseDate } from "../pages/restaurantProfilePage/utils";
import { DateTimeFormat, DayFormat, isValidDateTime, isValidTime } from "./utils";

/**
 * Gets link.
 * @param {String} routeLink
 * @param {Object} restaurantData
 */
const getLink = (routeLink, name, uuid) => {
  const sName = slugify(name || uuid);
  return routeLink.replace(":name", sName).replace(":uuid", uuid);
};

/**
 * Gets image source.
 * @param {String} restaurantUUID
 * @param {String} logoExtension
 * @param {String} [type='smallImage']
 * @param {String} [imagePrefix='']
 */
const getImageSource = (restaurantUUID, logoExtension, type = "smallImage", imagePrefix) => {
  let hasImagePrefix = false;
  if (imagePrefix) {
    hasImagePrefix = `/${imagePrefix}`;
  }

  return restaurantUUID && logoExtension
    ? `${baseConstants["BASE_URL"]}/image/${restaurantUUID}${
        hasImagePrefix ? hasImagePrefix : ""
      }/${type}.${logoExtension}`
    : `${baseConstants["BASE_URL"]}/image/${baseConstants["NO_IMAGE"]}`;
};

/**
 * Gets restaurant type.
 * @param {Object} preferences
 */
const getRestaurantType = (preferences) => {
  const defaultValue = (
    <Translation>
      {(t) => <React.Fragment>{t("defaultValues.restaurant")}</React.Fragment>}
    </Translation>
  );
  if (!preferences) {
    return defaultValue;
  }
  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          {t(
            `${
              Array.isArray(preferences) &&
              preferences[0] &&
              preferences[0].id &&
              preferences[0].id.value
                ? preferences[0].id.value
                : "defaultValues.restaurant"
            }`
          )}
        </React.Fragment>
      )}
    </Translation>
  );
};

/**
 * Gets open hours.
 * @param {Object} openHoursData
 * @param {string} [separator=', ']
 */
const getOpenHours = (openHoursData, separator = ", ") => {
  const dayList = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
  const hasOpenHours = openHoursData && Object.keys(openHoursData).length > 0;

  if (!hasOpenHours) {
    return [
      {
        day: "MONDAY",
        dayTo: "SUNDAY",
        openTime: null,
        closeTime: null
      }
    ];
  }

  const mergedOpenHours = dayList
    .map((dayName) => {
      const dayExists = typeof openHoursData[dayName] === "object";

      return dayExists
        ? {
            day: dayName,
            ...openHoursData[dayName]
          }
        : {
            day: dayName,
            openTime: null,
            closeTime: null
          };
    })
    .reduce((mergedDays, data, index, array) => {
      let previousIndex;
      let previousData;
      const newDay = {
        ...data,
        dayTo: data.day
      };

      if (index === 0) {
        mergedDays.push(newDay);
        return mergedDays;
      }
      previousIndex = index <= 1 ? 0 : mergedDays.length - 1;
      previousData = mergedDays[previousIndex];

      if (previousData.openTime === data.openTime && previousData.closeTime === data.closeTime) {
        mergedDays[previousIndex].dayTo = data.day;
      } else {
        mergedDays.push(newDay);
      }

      return mergedDays;
    }, [])
    .map((element) => {
      return {
        ...element,
        openTime: element.openTime ? element.openTime.split(":").slice(0, 2).join(":") : null,
        closeTime: element.closeTime ? element.closeTime.split(":").slice(0, 2).join(":") : null
      };
    });

  return mergedOpenHours;
};

/**
 * Gets first available time (current date + about 30 min).
 * @param {String} routeLink
 * @param {Object} restaurantData
 */
const getFirstAvailableTime = () => {
  const defaultReservationTimeFromSplitted = (
    baseConstants["DEFAULT_RESERVATION_TIME_FROM"]
      ? baseConstants["DEFAULT_RESERVATION_TIME_FROM"]
      : ""
  ).split(":");
  let currentDate = moment().format(DayFormat);
  let currentMinute = moment().add("30", "minutes").format("mm");
  let currentHour = moment().add("30", "minutes").format("HH");

  if (currentMinute > 0 && currentMinute <= 15) {
    currentMinute = "00";
  } else if (currentMinute > 15 && currentMinute <= 30) {
    currentMinute = "15";
  } else if (currentMinute > 30 && currentMinute <= 45) {
    currentMinute = "30";
  } else if (currentMinute > 45 && currentMinute <= 59) {
    currentMinute = "45";
  } else {
    currentMinute = "00";
    currentHour = moment(`${currentDate} ${currentHour}:${currentMinute}:00`)
      .add("60", "minutes")
      .format("HH");
  }

  if (parseInt(currentHour) === 0 && !(parseInt(moment().format("HH")) === 0)) {
    currentDate = moment().add(1, "day").format(DayFormat);
  }

  if (
    currentHour >= 0 &&
    currentHour <=
      (defaultReservationTimeFromSplitted[0]
        ? parseInt(defaultReservationTimeFromSplitted[0]) - 1
        : 9)
  ) {
    currentHour = defaultReservationTimeFromSplitted[0]
      ? defaultReservationTimeFromSplitted[0]
      : "";
    currentMinute = defaultReservationTimeFromSplitted[1]
      ? defaultReservationTimeFromSplitted[1]
      : "";
  }

  return {
    date: currentDate,
    hour: currentHour,
    minute: currentMinute
  };
};

/**
 * Gets full available hours.
 * @param {String} [language='pl']
 * @param {String} [startTime]
 * @param {String} [endTime]
 */
const getFullAvailableHours = (
  language = "pl",
  startTime = baseConstants["DEFAULT_RESERVATION_TIME_FROM"],
  endTime = "23:45",
  date = null
) => {
  const firstAvailableTime = restaurantUtils.getFirstAvailableTime();
  const toAddMinutes = 15;
  let hourListData = [];

  if (typeof startTime === "undefined") {
    startTime = baseConstants["DEFAULT_RESERVATION_TIME_FROM"];
  }

  for (let dayIndex = 0; dayIndex <= (date ? 1 : 0); dayIndex++) {
    const currentDate = moment(date ? date?.date ?? date : firstAvailableTime["date"])
      .add(dayIndex, "days")
      .format(DayFormat);

    for (let hourIndex = 0; hourIndex <= 95; hourIndex++) {
      const newHour = moment(`${currentDate} 00:00`)
        .add(toAddMinutes * hourIndex, "minutes")
        .format("HH:mm");
      const newHourEn = moment(`${currentDate} 00:00`)
        .add(toAddMinutes * hourIndex, "minutes")
        .locale("en")
        .format("LT");
      const newDateHour = moment(`${currentDate} 00:00`)
        .add(toAddMinutes * hourIndex, "minutes")
        .format(DateTimeFormat);
      const newData = {
        label: language === "en" ? newHourEn : newHour,
        value: newDateHour,
        hour: newHour
      };

      hourListData.push(newData);
    }
  }

  const startTimeIndex = hourListData.findIndex((hourItem) => hourItem.hour === startTime);
  //const endTimeIndex = hourListData.findIndex((hourItem) => hourItem.value === endTime);
  hourListData = hourListData.slice(startTimeIndex);

  return hourListData;
};

/**
 * Gets max date in future based on value (ISO 8601 format).
 * @param {String} [inFutureReservationDuration='PT1H']
 * @returns {Object<Moment>}
 */
const getMaxDateInFuture = (inFutureReservationDuration = "PT1H") => {
  const minutesToAdd = moment.duration(inFutureReservationDuration).asMinutes();
  const maxDate = moment().add(minutesToAdd, "minutes");
  return maxDate;
};

/**
 * Add GTM event.
 * @param {String} [name]
 */
const pushEventActionGTM = (name) => {
  if ("dataLayer" in window) {
    window.dataLayer.push({
      event: name
    });
  }
};

/**
 * Check user date matches the configuration of restaurant
 * @param {Number} [futureReservationDays]
 * @param {string} [selectedDate]
 */
const isNotValidBookingDate = (futureReservationDays, selectedDate) => {
  if (futureReservationDays && selectedDate) {
    const maxDayToMakeReservation = moment().add(futureReservationDays, "day");
    const userDate = moment(selectedDate);
    return moment(userDate).isAfter(maxDayToMakeReservation);
  }
  return false;
};

const getCompanyAddressName = (address: any | undefined) => {
  return address
    ? `${address.street}${address.buildingNumber ? ` ${address.buildingNumber}` : ""}${
        address.localNumber ? `/${address.localNumber}` : ""
      }${address.postalCode?.value ? ` ${address.postalCode?.value}` : ""}${
        address.country ? ` ${address.country}` : ""
      }`
    : "";
};

const getCompanyLabel = (company: any) => {
  return `${company?.name ?? ""} (NIP: ${company?.vatIdNumber?.value ?? ""}) ${
    company?.address ? getCompanyAddressName(company?.address) : ""
  }`;
};

const toDateTime = (formDate, firstAvailableTime, queryHour) =>
    `${parseDate(formDate, firstAvailableTime)}` +
    `T` +
    `${
      isValidTime(queryHour)
        ? queryHour
        : `${firstAvailableTime["hour"]}:${firstAvailableTime["minute"]}`
    }`


const searchSingleRestaurant = (
  restaurantUUID,
  queryDateTime,
  queryDate,
  formDate,
  firstAvailableTime,
  queryHour,
  formPersons,
  eventId,
  queryProduct
) => {
  const reservationTime = () => {
    if(isValidDateTime(queryDateTime)){
      return queryDateTime;
    }
    if (formDate) {
      return toDateTime(formDate, firstAvailableTime, queryHour);
    }
    return toDateTime(formDate, firstAvailableTime, queryHour);

  };
  const time = reservationTime()
  return requestService.searchSingleRestaurant(
    { value: restaurantUUID },
    time,
    formPersons,
    eventId,
    queryProduct
  );
};

const cityFromCookie = () => baseConstants["COOKIE_LOCATION"]?.name

const restaurantUtils = {
  cityFromCookie,
  getLink,
  getImageSource,
  getRestaurantType,
  getOpenHours,
  getFirstAvailableTime,
  getFullAvailableHours,
  getMaxDateInFuture,
  pushEventActionGTM,
  isNotValidBookingDate,
  getCompanyLabel,
  getCompanyAddressName,
  searchSingleRestaurant,
};


export default restaurantUtils;
